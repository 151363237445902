import React from 'react';
import TextField from '../../../../../../../components/inputField';

const SEOSettings = ({ newCampus, setnewCampus, onChange }) => {
  return (
    <div>
      {/* UNit Title and Meta Description row */}
      <div className='flex items-start justify-start gap-5'>
        <div className='w-1/2'>
          <TextField
            label={'Units Meta Title'}
            type='text'
            name='seoUnitTitle'
            value={newCampus?.seoUnitTitle}
            onChange={onChange}
          />
        </div>

        <div className='w-1/2'>
          <TextField
            label={'Units Meta Keywords'}
            type='text'
            name='seoKeywords'
            value={newCampus?.seoKeywords}
            onChange={onChange}
          />
        </div>
      </div>
      {/* Location Title and Location Description row */}
      <div className='flex items-start justify-start gap-5'>
        <div className='w-1/2'>
          <TextField
            label={'Units Meta Description'}
            type='text'
            name='seoMetaDescription'
            value={newCampus?.seoMetaDescription}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SEOSettings;
