import React from 'react';

function EmailIcon() {
  return (
    <React.Fragment>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
      >
        <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
          <g transform='translate(-322 -14)'>
            <g transform='translate(322 14)'>
              <path d='M0 0H20V20H0z'></path>
              <path
                fill='#CDA950'
                d='M15.917 14.02l-3.435-3.667-.41.33a3.204 3.204 0 01-2.03.71 3.215 3.215 0 01-2.04-.714l-.455-.358-3.466 3.696a3.494 3.494 0 01-.37-1.322l2.91-3.103-2.912-2.288c.037-.45.15-.863.333-1.229l4.71 3.704a2.055 2.055 0 002.566.007l4.635-3.722c.184.367.3.782.337 1.232l-2.89 2.32 2.888 3.084a3.502 3.502 0 01-.37 1.32zM17.5 12.41l.001-.006V7.597v-.004c0-.235-.026-.46-.062-.68a4.465 4.465 0 00-.086-.43l-.006-.035-.003.002a4.318 4.318 0 00-.453-1.138l.005-.004-.03-.044c-.038-.064-.081-.124-.123-.185-.1-.15-.21-.29-.327-.424-.035-.04-.068-.083-.105-.12a3.897 3.897 0 00-.51-.447c-.04-.028-.08-.053-.12-.08a3.83 3.83 0 00-.578-.327l-.045-.023a3.837 3.837 0 00-.84-.249 4.07 4.07 0 00-.735-.075H6.518a4.277 4.277 0 00-.864.098A3.822 3.822 0 003.08 5.319l.018.014c-.2.348-.348.73-.449 1.138l-.007-.006c-.007.031-.011.064-.018.096a4.708 4.708 0 00-.061.324c-.037.23-.064.465-.064.711v4.809c0 .26.028.51.071.754.03.181.075.353.124.527.006.024.01.05.017.074l.004-.003c.209.704.557 1.344 1.047 1.824.444.436.989.734 1.597.909.075.022.149.046.225.064.094.022.189.04.286.055.21.033.423.056.645.057h6.966c.247 0 .485-.028.717-.068l.05-.009a3.825 3.825 0 001.939-.97c.016-.014.033-.024.049-.038.028-.028.049-.065.076-.093.166-.172.322-.355.456-.557v-.002c.228-.35.39-.75.514-1.168l.01.01c.017-.06.025-.125.041-.187.034-.133.07-.263.092-.4a4.41 4.41 0 00.073-.775z'
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
}
export default EmailIcon;
