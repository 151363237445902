// Local Imports
import { Styles } from '../../utils/style/GlobalStyles';
import {
  gray,
  secondaryColor,
  yellow,
} from '../../utils/style/GlobalVariables';
import Button from '../button';

export function LinkPopover({ onLinkAdded, onClose, position, url, setUrl }) {
  const handleAddLink = () => {
    if (url) {
      onLinkAdded(url);
    }
    setUrl('');
  };

  return (
    <div
      className={`bg-[${secondaryColor}]  p-4 z-50  rounded-lg shadow-lg w-[30%] mt-4 border border-[${gray}]`}
      style={{ position: 'absolute', top: position.y, left: position.x }}
    >
      <input
        className={`p-3 text-[13px] text-white bg-transparent w-full !border-[${yellow}] border-[1px] rounded-[8px] font-["Inter"]`}
        type='text'
        placeholder='Enter URL'
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <div className='flex gap-2 mt-2'>
        <Button
          height={37}
          className={`!px-5 text-sm !normal-case`}
          style={Styles.activeBtn}
          component='span'
          variant='contained'
          onClick={handleAddLink}
        >
          <p style={Styles.activeBtnText}>Save</p>
        </Button>
        <Button
          height={37}
          style={{
            borderColor: gray,
            color: gray,
            fontFamily: 'Inter-Medium',
            fontSize: 13,
            textTransform: 'none',
            borderRadius: 8,
            width: 'max-content',
          }}
          component='span'
          variant='outlined'
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export function createImageTag(
  editorRef,
  exeCmd,
  setIsShowBlock,
  setActiveCommands,
  setImageSizeError,
  setNewPostMedia
) {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.onchange = (e) => {
    const file = e.target.files[0];
    if (file && file.size < 5e6) {
      const imgURL = URL.createObjectURL(file);
      const container = document.createElement('div');
      const p = document.createElement('p');
      p.innerText = ' ';
      container.id = 'img-container';
      container.setAttribute('contentEditable', 'false');
      const image = document.createElement('img');
      image.src = imgURL;
      container.appendChild(image);
      // Create the close button
      const closeButton = document.createElement('button');
      closeButton.classList.add('remove-img-btn');
      closeButton.innerHTML = 'X';
      closeButton.addEventListener('click', () => {
        setIsShowBlock(() => ({
          isVideoOptions: false,
          isTypoOptions: true,
          isImageOptions: false,
        }));
        container.remove();
        setNewPostMedia((prev) => ({
          ...prev,
          blogImages: prev.blogImages.filter((img) => img !== file),
        }));
      });
      container.appendChild(closeButton);
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(container);
      // Move the cursor to the end of the inserted content
      range.setStartAfter(container);
      range.setEndAfter(container);
      selection.removeAllRanges();
      selection.addRange(range);
      setNewPostMedia((prev) => ({
        ...prev,
        blogImages: [...prev.blogImages, file],
      }));
      setActiveCommands((prevState) => ({
        ...prevState,
        [exeCmd]: false,
      }));
      editorRef.current.appendChild(p);
    } else {
      setActiveCommands((prevState) => ({
        ...prevState,
        insertImage: false,
      }));
      setImageSizeError('The image must not be greater than 5MB.');
    }
  };
  fileInput.click();
  return fileInput;
}

export function createVideoTag(
  editorRef,
  exeCmd,
  setIsShowBlock,
  setActiveCommands,
  setVideoSizeError,
  setNewPostMedia
) {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'video/*';
  fileInput.onchange = (e) => {
    const file = e.target.files[0];
    if (file && file.size < 10e6) {
      const videoURL = URL.createObjectURL(file);
      const container = document.createElement('div');
      container.id = 'video-container';
      const p = document.createElement('p');
      p.innerText = ' ';
      p.setAttribute('contentEditable', 'false');
      // container.setAttribute('contentEditable', 'false');
      const videoTag = document.createElement('video');
      videoTag.controls = true;
      videoTag.src = videoURL;
      container.appendChild(videoTag);
      const closeButton = document.createElement('button');
      closeButton.innerHTML = 'X';
      closeButton.className = 'remove-video-btn';
      closeButton.addEventListener('click', () => {
        setIsShowBlock(() => ({
          isVideoOptions: false,
          isTypoOptions: true,
          isImageOptions: false,
        }));
        container.remove();
        setNewPostMedia((prevData) => ({
          ...prevData,
          blogVideos: prevData.blogVideos?.filter((img) => img !== file),
        }));
      });
      container.appendChild(closeButton);
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(container);
      range.setStartAfter(container);
      range.setEndAfter(container);
      selection.removeAllRanges();
      selection.addRange(range);
      setNewPostMedia((prevData) => ({
        ...prevData,
        blogVideos: [...prevData.blogVideos, file],
      }));
      setActiveCommands((prevState) => ({
        ...prevState,
        [exeCmd]: false,
      }));
      editorRef.current.appendChild(p);
    } else {
      setActiveCommands((prevState) => ({
        ...prevState,
        [exeCmd]: false,
      }));
      setVideoSizeError('The video must not be greater than 10MB');
    }
  };
  fileInput.click();
}
