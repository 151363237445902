import React, { useEffect } from 'react';
import TextField from '../../../../../../components/inputField';

const SEOSettings = ({ newUnit, onChange }) => {

  return (
    <div>
      {/* Length and width row */}
      <div className='flex items-start justify-start gap-5'>
        <div className='w-1/2'>
          <TextField
            label={'Unit Meta Title'}
            type='text'
            name='seoUnitTitle'
            value={newUnit?.seoUnitTitle}
            onChange={onChange}
            disabled={newUnit?.title === "Edit Unit" ? false : true}
          />
        </div>
        <div className='w-1/2'>
          <TextField
            label={'Unit Meta Keywords'}
            type='text'
            name='seoKeywords'
            value={newUnit?.seoKeywords}
            onChange={onChange}
            disabled={newUnit?.title === "Edit Unit" ? false : true}
          />
        </div>
      </div>

      {/* Length and width row */}
      <div className='flex items-start justify-start gap-5'>
        <div className='w-1/2'>
          <TextField
            label={'Unit Meta Description'}
            type='text'
            name='seoMetaDescription'
            value={newUnit?.seoMetaDescription}
            onChange={onChange}
            disabled={newUnit?.title === "Edit Unit" ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default SEOSettings;
