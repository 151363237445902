// Library Imports
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Chip, IconButton, Popover, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch } from 'react-redux';
import {
  MenuItem,
  Autocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// Local Imports
import BreadCrumb from '../../../../../components/BreadCrumb';
import Button from '../../../../../components/button';
import TextField from '../../../../../components/inputField';
import DragDropFile from '../../../../../components/DragDropFile/DragDropFile';
import {
  yellow,
  gray,
  red,
  secondaryColor,
  white,
  primaryColor,
} from '../../../../../utils/style/GlobalVariables';
import { Styles } from '../../../../../utils/style/GlobalStyles';
import Radio from '../../../../../components/RadioButton/Radio';
import SelectDropdown from '../../../../../components/selectDropdown';
import MuiSwtich from '../../../../../components/MUISwtich';
import { previewReducer } from '../../../../../redux/reducers/previewReducer';
import ApiController from '../../../../../utils/network/api';
import { InsideSpinner } from '../../../../../components/Spinner/Spinner';
import DeleteBlogPage from './DeleteBlogUI';
import { imageSizeList, getCurrentDate } from '../../../../../utils/utils';
import { BLOGURLFORWEB } from '../../../../../utils/network/constants';
import CustomEditor from '../../../../../components/Editor';
import PageLinking from '../../../../../components/Editor/PageLinking';
import CustomModal from '../../../../../components/Modal/Modal';

const AddNewBlogPage = () => {
  const { id } = useParams();
  //REFS
  const taglistRef = useRef(null);
  const date = getCurrentDate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newPost, setNewPost] = useState({
    title: '',
    description: '<p>This is a sample text.</p>',
    saveDraft: false,
    publishPost: false,
    publish: 'IMMEDIATELY',
    visibility: 'public',
    url: BLOGURLFORWEB,
    customURI: 'enter-uri',
    password: '',
    publishDate: date,
    imagesForEdit: [],
    videosForEdit: [],
    blogTitle: '',
    keywords: '',
    blogMetaDescription: '',
  });

  const [generatePost, setGeneratePost] = useState({
    blogTopic: '',
    blogSections: '',
    blogWords: '',
    blogKeywords: [],
  });

  const [generatePostError, setGeneratePostError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteBlogContent, setDeleteBlogContent] = useState(false);
  const [links, setLinks] = useState([]);
  const [newPostMedia, setNewPostMedia] = useState({
    featureImage: '',
    blogImages: [],
    blogVideos: [],
    featureFrom: 'onChange',
    featureImgForPayload: '',
  });

  const [newPostVideoSettings, setNewPostVideoSettings] = useState({
    autoplay: false,
    loop: false,
    muted: false,
    playbackControls: false,
    playInline: false,
  });

  const [newPostMediaError, setNewPostMediaError] = useState({
    type: '',
    message: '',
  });
  const [editorHTML, setEditorHTML] = useState(null);
  const childRef = useRef(null);

  const [isShowBlock, setIsShowBlock] = useState({
    isTypoOptions: true,
    isImageOptions: false,
    isVideoOptions: false,
  });
  const [backendError, setBackendError] = useState({});
  const [loading, setLoading] = useState(false);
  const [generatePostLoading, setGeneratePostLoading] = useState(false);
  const [getBlogPostLoading, setGetBlogPostLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [existingTagsList, setExistingTagsList] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);

  // Delete Modal States
  const [deleteBlogData, setDeleteBlogData] = useState({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  // States and Functions For Visibility Popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const visibilityId = open ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update Links in Description Editor
  const updateLinks = () => {
    if (childRef.current) {
      childRef.current.updateLinks();
    }
  };

  const handleRemoveImage = (file, ImageIndex) => {
    const updatedImagesList = newPost?.postImages?.filter((file, index) => {
      return index !== ImageIndex;
    });
    setNewPost((prev) => ({
      ...prev,
      postImages: [...updatedImagesList],
    }));
  };

  const onInputClick = (event) => {
    event.target.value = '';
    setNewPostMediaError({ type: '', message: '' });
  };

  // Handle Generate Post OnChange text Fields
  const generatePostOnChange = (e) => {
    setGeneratePostError('');
    // On Change Blog Fields
    const { name, value } = e.target;
    if (name === 'blogKeywords') {
      setGeneratePost((prev) => ({
        ...prev,
        [name]: value?.replace(/\s+/g, ',')?.replace(/,\s*$/, ' '),
      }));
    } else {
      setGeneratePost((prev) => ({ ...prev, [name]: value }));
    }
  }

  // Handle OnChange Text Fields
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (name === 'keywords') {
      setNewPost((prev) => ({
        ...prev,
        [name]: value?.replace(/\s+/g, ',')?.replace(/,\s*$/, ' '),
      }));
    } else if (name === 'title') {
      setNewPost((prev) => ({
        ...prev,
        customURI: value === '' ? 'enter-uri' : value?.toLowerCase()?.replaceAll(' ', '-'),
        [name]: value,
      }));
    } else {
      setNewPost((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Handle onChange URL
  const handleOnChangeURL = (event) => {
    const inputValue = event.target.value;
    const prefix = newPost.url;
    const regex = /[^a-zA-Z0-9-]+/g;
    const value = inputValue.split('/')[4];
    if (!regex.test(value) || value === '') {
      if (inputValue.startsWith(prefix)) {
        const cleanedValue = value.replace(/-+/g, '-');
        setNewPost((prev) => ({
          ...prev,
          customURI: inputValue
            .substring(prefix.length)
            .replace(value, cleanedValue),
        }));
      }
      setNewPostMediaError({
        type: '',
        message: '',
      });
    } else {
      setNewPostMediaError({
        type: 'uriError',
        message: "You can't use underscores and special characters",
      });
    }
  };

  // Hanlde Visibility OnCahnge
  const handleVisibilityChange = (event) => {
    setNewPost((prevData) => ({
      ...prevData,
      visibility: event.target.value,
    }));
  };
  // Hanlde Preview The Post Page
  const handlePreviewPostPage = () => {
    const imageURL =
      newPostMedia.featureFrom === 'link'
        ? newPostMedia?.featureImage?.url
        : URL.createObjectURL(newPostMedia?.featureImage);
    const postDetails = {
      title: newPost?.title,
      blog: editorHTML,
      featureImage: imageURL,
      tags: tagsList,
      from: 'blog',
    };
    dispatch(previewReducer(postDetails));
    window.open('/preview-page', '_blank');
  };

  // Handle Tabs States And Methods
  const [value, setValue] = useState(0);
  const handleTabValue = (event, newValue) => {
    setValue(newValue);
  };

  // Handle Tags States And Methods
  const handleTagSelection = (tag) => {
    if (!tagsList.includes(tag)) {
      setTagsList((prev) => [...prev, tag]);
    }
  };

  const handleTagRemoval = (tag) => {
    setTagsList((prev) => prev.filter((t) => t !== tag));
  };

  // Editor States And Methods
  const [imageWidth, setImageWidth] = useState('');
  const [imageHeight, setImageHeight] = useState('');
  const [letterCases, setLetterCases] = useState({
    underscore: false,
    capitilize: false,
    upperCase: false,
    lowerCase: false,
  });
  const [imageSize, setImageSize] = useState('');
  const [videoSizeError, setVideoSizeError] = useState('');
  const [imageSizeError, setImageSizeError] = useState('');

  // ***************************************  Handle On Change Feature Media Uploads Start    *********************************** //

  const handleOnChangeFeatureImage = (file) => {
    if (file[0]?.size < 5e6) {
      const files = Array.from(file);
      setNewPostMedia((prevData) => ({
        ...prevData,
        featureImage: file[0],
        featureImgForPayload: file[0],
        featureFrom: 'onChange',
      }));
      setNewPostMediaError({ type: '', message: '' });
    } else {
      setNewPostMediaError({
        type: 'featureImage',
        message: 'The image must not be greater than 5MB.',
      });
    }
  };
  // Handle Drop For Feature Image
  const handleDropFeatureImage = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files[0]?.size < 5e6) {
      setNewPostMedia((prevData) => ({
        ...prevData,
        featureImage: files[0],
        featureImgForPayload: file[0],
        featureFrom: 'onChange',
      }));
      setNewPostMediaError({ type: '', message: '' });
    } else {
      setNewPostMediaError({
        type: 'featureImage',
        message: 'The image must not be greater than 5MB.',
      });
    }
  };

  const hideErrorMessages = () => {
    setNewPostMediaError({
      type: '',
      message: '',
    });
  };

  const handleOnChangeVideoSetting = (e) => {
    setNewPostVideoSettings((set) => ({
      ...set,
      [e.target.name]: e.target.checked,
    }));
  };

  // ************************************* Handle Typo Options *************************************//
  const handleTypoOptions = (type) => {
    childRef?.current?.handleTypoOption(type);
  };

  // ********************** Create Payload For Add New Blog ********************* //
  const createPayload = (action, id) => {
    const data = {
      title: newPost.title,
      description: editorHTML,
      publish: action === 'draft' ? false : true,
      visibility: newPost.visibility?.toUpperCase(),
      password: newPost.password,
      tags: tagsList,
      keywords: links?.map(item => ({
        word: item.keyword,
        link: item.link
      })),
      scheduled_publish_date: `${newPost.publishDate}T00:00:00`,
      ...(id
        ? {
          end_url_during_update: newPost.customURI,
        }
        : {
          end_url: newPost.customURI,
        }),
      blog_seo_title: newPost?.blogTitle || null,
      blog_seo_description: newPost?.blogMetaDescription || null,
      blog_seo_keywords:
        newPost?.keywords?.trim() !== ''
          ? newPost?.keywords
            ?.trim()
            ?.split(',')
            ?.filter((item) => item !== '' && item !== ' ')
          : null,
    };
    const payload = {
      data,
      blogId: id,
    };
    return payload;
  };

  // ********************** Create Payload For Add New Blog Images Uplaod ********************* //
  const createPayloadForImagesUpload = (id) => {
    let formData = new FormData();
    if (newPostMedia.featureImgForPayload) {
      formData.append('image', newPostMedia.featureImgForPayload);
      formData.append('featured', 'image');
    }
    newPostMedia.blogImages?.forEach((file, index) => {
      formData.append(`image ${index + 1}`, file);
    });
    newPostMedia.blogVideos?.forEach((video, index) => {
      formData.append(`video ${index + 1}`, video);
    });
    formData.append('autoplay', Boolean(newPostVideoSettings?.autoplay));
    formData.append('loop', Boolean(newPostVideoSettings?.loop));
    formData.append('muted', Boolean(newPostVideoSettings?.muted));
    formData.append(
      'playback_controls',
      Boolean(newPostVideoSettings?.playbackControls)
    );
    formData.append('play_inline', Boolean(newPostVideoSettings?.playInline));
    const payload = {
      blogId: id,
      formData: formData,
    };
    return payload;
  };

  // Delete Images Payload
  const deleteImagesPayload = () => {
    const ids = deletedIds?.map((ids) => {
      return ids?.id;
    });
    return {
      ids: ids,
    };
  };
  // Update Video Controls Payload
  const updateVideoControlsPayload = () => {
    let formData = new FormData();
    formData.append('autoplay', Boolean(newPostVideoSettings?.autoplay));
    formData.append('loop', Boolean(newPostVideoSettings?.loop));
    formData.append('muted', Boolean(newPostVideoSettings?.muted));
    formData.append(
      'playback_controls',
      Boolean(newPostVideoSettings?.playbackControls)
    );
    formData.append('play_inline', Boolean(newPostVideoSettings?.playInline));
    const payload = {
      blogId: newPost.videosForEdit?.id,
      formdata: formData,
    };
    return payload;
  };

  // ********************** onSubmit ********************* //
  const handlePublishBlogPage = async (action) => {
    if (!id) {
      setLoading(true);
      ApiController.addNewBlogPageCall(createPayload(action), (response) => {
        if (response.success) {
          if (
            newPostMedia.featureImgForPayload !== '' ||
            newPostMedia.blogImages?.length > 0 ||
            newPostMedia.blogVideos?.length > 0
          ) {
            ApiController.addNewBlogPageUploadImagesCall(
              createPayloadForImagesUpload(response?.data?.blog_id),
              (imgresponse) => {
                if (imgresponse.success) {
                  setLoading(false);
                  navigate('/pages');
                } else {
                  setLoading(false);
                }
              }
            );
          }
        } else {
          setLoading(false);
          const errors = response?.data?.reduce(
            (acc, curr) => ({ ...acc, curr }),
            {}
          );
          setBackendError(errors);
        }
      });
    }
    if (id) {
      setLoading(true);
      ApiController.updateBlogCall(createPayload(action, id), (response) => {
        if (response.success) {
          if (
            newPostMedia.featureImgForPayload !== '' ||
            newPostMedia.blogImages?.length > 0 ||
            newPostMedia.blogVideos?.length > 0
          ) {
            ApiController.addNewBlogPageUploadImagesCall(
              createPayloadForImagesUpload(id),
              (imgresponse) => {
                if (imgresponse.success) {
                  // Delete Images API START
                  if (deletedIds.length > 0) {
                    ApiController.deleteBlogImagesCall(
                      deleteImagesPayload(),
                      (deleteResponse) => {
                        if (deleteResponse.success) {
                          setLoading(false);
                          navigate('/pages');
                        }
                      }
                    );
                  } else {
                    setLoading(false);
                    navigate('/pages');
                  }
                  // Delete Images API START
                } else {
                  setLoading(false);
                  navigate('/pages');
                }
              }
            );
          } else {
            // Delete Images API START
            if (deletedIds.length > 0) {
              ApiController.deleteBlogImagesCall(
                deleteImagesPayload(),
                (deleteResponse) => {
                  if (deleteResponse.success) {
                    setLoading(false);
                    navigate('/pages');
                  }
                }
              );
            } else {
              setLoading(false);
              navigate('/pages');
            }
            // Delete Images API START
          }
          // Update Video Controls
          if (newPost.videosForEdit) {
            ApiController.updateVideoControlsCall(
              updateVideoControlsPayload(),
              (response) => {
                if (response.success) {
                  console.log('response of video controls');
                }
              }
            );
          }
        } else {
          setLoading(false);
          const errors = response?.data?.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          );
          setBackendError(errors);
        }
      });
    }
  };
  // ********************** useEffect For Get Single Blog Data ********************* //

  useEffect(() => {
    if (id) {
      setGetBlogPostLoading(true);
      ApiController.getSingleBlogPageDetailsCall(id, (response) => {
        if (response.success) {
          const data = response?.data;
          setGetBlogPostLoading(false);
          const featureImg = data?.blog_images?.filter(
            (item) => item.featured === 'featured'
          )[0];
          setNewPost((prev) => ({
            ...prev,
            title: data?.title,
            description: data?.description,
            visibility: data?.visibility?.toLowerCase(),
            password: data?.password,
            imagesForEdit: data?.blog_images,
            customURI: data?.end_url ? data?.end_url : 'enter-uri',
            publishDate: data?.scheduled_publish_date?.split('T')[0],
            blogTitle: data?.blog_seo_title || '',
            keywords:
              data?.blog_seo_keywords?.length > 0
                ? data?.blog_seo_keywords?.join(',')
                : '',
            blogMetaDescription: data?.blog_seo_description || '',
          }));
          setEditorHTML(data?.description);
          setNewPostMedia((prev) => ({
            ...prev,
            featureImage: featureImg !== undefined ? featureImg : '',
            featureFrom: 'link',
          }));
          const linksData = data?.keywords?.map(item => ({
            keyword: item?.word,
            link: item?.link
          }))
          setLinks(linksData);
          setTagsList(data?.tags);
          setDeleteBlogData(data);
        } else {
          setGetBlogPostLoading(false);
        }
      });
    }
  }, [id]);

  //************************* DELETE CAMPUS START *******************************//
  const handleOnDelete = (id) => {
    setDeleteLoading(true);
    ApiController.deleteBlogPageCall(id, (response) => {
      if (response?.success) {
        setDeleteLoading(false);
        setDeleteError('');
        setIsOpenDeleteModal(false);
        navigate('/pages');
      } else {
        setDeleteLoading(false);
        setDeleteError(response?.message);
      }
    });
  };

  // Handle Close Delete Modal
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setDeleteError('');
    setDeleteLoading(false);
    setDeleteBlogData({});
  };

  // PREVIEW BUTTON VALIDATION
  const previewButtonValidation = () => {
    const { title, description } = newPost;
    const { featureImage } = newPostMedia;

    if (!title || !description || !featureImage || tagsList.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // FETCH TAGS LISt
  const fetchTagsList = () => {
    ApiController.getTagsListingCall('', (response) => {
      if (response.success) {
        const tags = response?.data?.tags;
        setExistingTagsList(tags);
      }
    });
  };

  // Check Blog Content 
  const checkBlogContent = () => {
    const isEmpty = (
      Object.values(generatePost).every(value => value !== '') &&
      generatePost.blogKeywords.length > 0
    );

    if (!isEmpty) {
      setGeneratePostError('Please fill all the fields');
      return;
    }

    if (editorHTML !== null && editorHTML !== undefined && editorHTML !== '') {
      setModalOpen(true);
    } else {
      generateBlog();
    }
  }

  // GENERATE BLOG
  const generateBlog = () => {
    const isEmpty = (
      Object.values(generatePost).every(value => value !== '') &&
      generatePost.blogKeywords.length > 0
    );

    if (!isEmpty) {
      setGeneratePostError('Please fill all the fields');
      return;
    }

    let payload = {
      topics: [generatePost?.blogTopic],
      keywords: generatePost?.blogKeywords?.length > 0
        ? generatePost?.blogKeywords
          ?.trim()
          ?.split(',')
          ?.filter((item) => item !== '' && item !== ' ')
        : null,
      parts: generatePost?.blogSections,
      words: generatePost?.blogWords,
    }
    setGeneratePostLoading(true);
    ApiController.generateBlog(payload, (response) => {
      if (response.success) {
        setGeneratePostLoading(false);
        setNewPost((prev) => ({
          ...prev,
          customURI: response?.data?.title === '' ? 'enter-uri' : response?.data?.title?.toLowerCase()?.replaceAll(' ', '-'),
          title: response?.data?.title
        }));
        const cleanedText = response?.data?.body?.replace(/\[|\]/g, '')?.replace(/<\/?body>/g, '');
        setEditorHTML(cleanedText);
        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanedText, 'text/html');
        const links = [...doc.querySelectorAll('a')].map((anchor) => ({
          keyword: anchor.textContent,
          link: anchor.href
        }));
        setLinks((prev) => [...prev, ...links]);
        setGeneratePostError('');
      } else {
        setGeneratePostLoading(false);
        if (response?.data === "Network Error") {
          setGeneratePostError(response?.data)
        }
      }
      setDeleteBlogContent(false);
    });
  };

  useEffect(() => {
    updateLinks();
  }, [links]);

  useEffect(() => {
    fetchTagsList();
  }, []);

  // Hide Errors
  useEffect(() => {
    setTimeout(() => {
      setVideoSizeError('');
      setImageSizeError('');
    }, 5000);
  }, [videoSizeError, imageSizeError]);

  // Set Scroll To Bottom When Tags Adding
  useEffect(() => {
    if (taglistRef.current) {
      taglistRef.current.scrollTop = taglistRef.current?.scrollHeight;
    }
  }, [tagsList]);

  return (
    <React.Fragment>
      <div className='main-container '>
        {/* Header BreadCrumb with Buttom Row */}
        <div className='flex flex-row justify-between items-center py-5 px-5'>
          <div>
            <BreadCrumb routes={[{ name: 'Pages' }]} />
            <div
              style={{ fontFamily: 'Inter-Medium', fontSize: 15 }}
              className='text-white pt-1'
            >
              <span>{id ? 'Edit Post' : 'New Post'} </span>
            </div>
          </div>
          <div className='flex flex-row gap-3'>
            <Button
              height={37}
              style={{
                color: previewButtonValidation() ? gray : yellow,
                fontFamily: 'Inter-Medium',
                fontSize: 13,
                textTransform: 'none',
                width: '100px',
                borderRadius: 8,
                boxShadow: 'none',
                backgroundColor: loading ? secondaryColor : 'unset',
              }}
              component='span'
              variant='text'
              loading={loading}
              disabled={previewButtonValidation() || loading}
              onClick={() => handlePublishBlogPage('draft')}
            >
              {!loading && 'Save Draft'}
            </Button>
            <Button
              height={37}
              style={{
                borderColor:
                  previewButtonValidation() || loading ? gray : yellow,
                color: previewButtonValidation() || loading ? gray : yellow,
                fontFamily: 'Inter-Medium',
                fontSize: 13,
                textTransform: 'none',
                width: '100px',
                borderRadius: 8,
              }}
              component='span'
              variant='outlined'
              disabled={previewButtonValidation() || loading}
              onClick={handlePreviewPostPage}
            >
              {'Preview'}
            </Button>
            <Button
              height={37}
              className={`!px-5 text-sm !normal-case`}
              style={
                previewButtonValidation() || loading
                  ? Styles?.disableBtn
                  : Styles.activeBtn
              }
              component='span'
              variant='contained'
              disabled={previewButtonValidation() || loading}
              loading={loading}
            >
              <p
                style={
                  previewButtonValidation() || loading
                    ? Styles?.disableBtnText
                    : Styles.activeBtnText
                }
                onClick={() => handlePublishBlogPage('publish')}
              >
                {!loading && 'Publish'}
              </p>
            </Button>
          </div>
        </div>
        {getBlogPostLoading ? (
          <InsideSpinner />
        ) : (
          <section
            style={{ borderColor: secondaryColor }}
            className={`flex border-t gap-5`}
          >
            {/* First Section */}
            <div className='w-3/4 flex flex-col h-[calc(100vh-78px)] overflow-auto pb-5 px-3'>
              <div className='w-full'>
                <TextField
                  value={newPost?.title}
                  onChange={(e) => handleOnChange(e)}
                  label='Title'
                  type='text'
                  name='title'
                />
              </div>
              <div>
                <p style={Styles.smallTextWhite} className='mb-1 mt-[20px]'>
                  Description
                </p>
                <CustomEditor
                  id={id}
                  newPost={newPost}
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  imageSize={imageSize}
                  setValue={setValue}
                  setNewPostMedia={setNewPostMedia}
                  setImageSizeError={setImageSizeError}
                  setIsShowBlock={setIsShowBlock}
                  setImageWidth={setImageWidth}
                  setImageHeight={setImageHeight}
                  setVideoSizeError={setVideoSizeError}
                  editorHTML={editorHTML}
                  letterCases={letterCases}
                  setLetterCases={setLetterCases}
                  setEditorHTML={setEditorHTML}
                  ref={childRef}
                  videoSettings={newPostVideoSettings}
                  setVideoSettings={setNewPostVideoSettings}
                  setDeletedIds={setDeletedIds}
                  links={links}
                  updateLinks={updateLinks}
                  deleteBlogContent={deleteBlogContent}
                />
                {videoSizeError !== '' ? (
                  <p style={{ color: red }} className='text-sm'>
                    {videoSizeError}
                  </p>
                ) : null}
                {imageSizeError !== '' ? (
                  <p style={{ color: red }} className='text-sm'>
                    {imageSizeError}
                  </p>
                ) : null}
              </div>
            </div>

            {/* Second Section */}
            <div
              className='w-[350px] text-white'
              style={{ backgroundColor: secondaryColor }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    background: yellow,
                  },
                }}
                value={value}
                onChange={handleTabValue}
                aria-label='basic tabs example'
                sx={{ borderBottom: `1px solid ${primaryColor}` }}
              >
                <Tab
                  style={{
                    textTransform: 'none',
                    fontSize: 13,
                    fontFamily: 'Inter-Medium',
                    color: value === 0 ? white : gray,
                  }}
                  label='Post'
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    textTransform: 'none',
                    fontSize: 13,
                    fontFamily: 'Inter-Medium',
                    color: value === 1 ? white : gray,
                  }}
                  label='Block'
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    textTransform: 'none',
                    fontSize: 13,
                    fontFamily: 'Inter-Medium',
                    color: value === 2 ? white : gray,
                  }}
                  label='SEO'
                  {...a11yProps(2)}
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <div className='h-[calc(100vh-132px)] overflow-auto'>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm'>Summary</p>
                    <div className='mt-5 flex flex-col gap-5'>
                      <p className='text-sm flex justify-between'>
                        <span style={{ color: gray }}>Visibility</span>
                        <span
                          style={{ color: yellow }}
                          className='underline cursor-pointer'
                          onClick={handleClick}
                        >
                          {newPost?.visibility === 'public'
                            ? 'Public'
                            : 'Private'}
                        </span>
                      </p>

                      <div className='flex justify-between items-center'>
                        <p className='text-sm flex justify-between'>
                          <span style={{ color: gray }}>Publish</span>
                        </p>
                        <div className='flex justify-start item-end'>
                          <input
                            type='date'
                            name='publishDate'
                            onChange={(event) => handleOnChange(event)}
                            value={newPost.publishDate}
                            className={`bg-transparent hideIcon -mr-3 text-[${yellow}] shadow-none focus:shadow-none focus:border-b focus:border-[${yellow}]`}
                          />
                        </div>
                      </div>
                      <p className='text-sm flex justify-between flex-col'>
                        <span style={{ color: gray }}>URl</span>
                        <input
                          className={`bg-transparent text-[${yellow}] shadow-none focus:shadow-none focus:border-b focus:border-[${yellow}]`}
                          type='text'
                          value={`${newPost.url}${newPost.customURI}`}
                          onChange={handleOnChangeURL}
                        />
                        <span className={`text-xs mt-1 text-red-500`}>
                          {newPostMediaError.type === 'uriError' &&
                            newPostMediaError.message}
                          {backendError?.non_field_errors !== '' &&
                            backendError?.non_field_errors}
                        </span>
                      </p>
                    </div>
                  </div>
                  {!id ? (
                    <div className={`pb-5 px-4 flex flex-col border-b border-[${primaryColor}]`}>
                      <div className='w-full'>
                        <TextField
                          label={'Blog Topic'}
                          type='text'
                          name='blogTopic'
                          textArea
                          style={{
                            borderWidth: '1px',
                            borderColor: yellow,
                          }}
                          value={generatePost?.blogTopic}
                          onChange={(e) => generatePostOnChange(e)}
                        />
                      </div>
                      <div className='w-full'>
                        <TextField
                          label={'Blog Sections'}
                          type='text'
                          name='blogSections'
                          style={{
                            borderWidth: '1px',
                            borderColor: yellow,
                          }}
                          value={generatePost?.blogSections}
                          onChange={(e) => generatePostOnChange(e)}
                        />
                      </div>
                      <div className='w-full'>
                        <TextField
                          label={'Blog Words'}
                          type='text'
                          name='blogWords'
                          style={{
                            borderWidth: '1px',
                            borderColor: yellow,
                          }}
                          value={generatePost?.blogWords}
                          onChange={(e) => generatePostOnChange(e)}
                        />
                      </div>
                      <div className='w-full'>
                        <TextField
                          label={'Blog Keywords'}
                          type='text'
                          name='blogKeywords'
                          style={{
                            borderWidth: '1px',
                            borderColor: yellow,
                          }}
                          value={generatePost?.blogKeywords}
                          onChange={(e) => generatePostOnChange(e)}
                        />
                      </div>
                      <div className='flex justify-between gap-2 mt-3'>
                        <p className='text-red-500 text-xs'>{generatePostError}</p>
                        <Button
                          height={37}
                          style={{
                            color: yellow,
                            fontFamily: 'Inter-Medium',
                            fontSize: 13,
                            textTransform: 'none',
                            borderRadius: 8,
                            boxShadow: 'none',
                            borderColor: yellow,
                          }}
                          component='span'
                          variant='outlined'
                          onClick={checkBlogContent}
                          loading={generatePostLoading ? true : false}
                        >
                          {generatePostLoading ? '' : 'Generate'}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={`py-5 px-4 flex flex-col border-b border-[${primaryColor}]`}
                  >
                    <div className={` flex justify-between items-center`}>
                      {id && (
                        <p
                          style={{ color: red }}
                          className={`text-sm font-medium cursor-pointer p-2 hover:bg-red-600/5 rounded-[8px]  duration-200`}
                          onClick={() => setIsOpenDeleteModal(true)}
                        >
                          Move to Trash
                        </p>
                      )}
                      <Button
                        height={37}
                        style={{
                          color: yellow,
                          fontFamily: 'Inter-Medium',
                          fontSize: 13,
                          textTransform: 'none',
                          borderRadius: 8,
                          boxShadow: 'none',
                          borderColor: yellow,
                        }}
                        component='span'
                        variant='outlined'
                        onClick={() => navigate('/pages')}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div
                    className={`py-5 px-4 border-b  border-[${primaryColor}]`}
                  >
                    <p className='text-sm'>Tags</p>
                    <Box>
                      <Box>
                        {tagsList.length > 0 && (
                          <Box
                            color={white}
                            display='flex'
                            flexWrap='wrap'
                            gap='5px'
                            marginBottom='8px'
                            marginTop='8px'
                            maxHeight='200px'
                            overflow='scroll'
                            ref={taglistRef}
                          >
                            {tagsList?.map((tag, index) => {
                              return (
                                <Chip
                                  variant='filled'
                                  size='small'
                                  deleteIcon={<CloseRoundedIcon />}
                                  key={index}
                                  label={tag}
                                  onDelete={() => handleTagRemoval(tag)}
                                  sx={{
                                    color: white,
                                    borderRadius: '4px',
                                    backgroundColor: primaryColor,
                                    '& .MuiChip-deleteIcon': {
                                      color: white,
                                      '&:hover': {
                                        color: yellow,
                                      },
                                    },
                                  }}
                                />
                              );
                            })}
                          </Box>
                        )}
                        <Autocomplete
                          sx={{
                            m: 1,
                            color: 'white',
                            border: `1px solid ${yellow}`,
                            borderRadius: '8px',
                            '& .css-hzaytx-MuiInputBase-root-MuiOutlinedInput-root':
                            {
                              color: white,
                            },
                            '& .css-4duybk-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input':
                            {
                              boxShadow: 'none',
                            },
                            '& .css-i4bv87-MuiSvgIcon-root': {
                              color: yellow,
                            },
                            input: {
                              boxShadow: 'none !important',
                              color: 'white',
                            },
                            svg: {
                              fill: `${yellow}`,
                            },
                          }}
                          color={'success'}
                          noOptionsText
                          options={existingTagsList}
                          getOptionLabel={(option) => option}
                          size='small'
                          disableCloseOnSelect
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{ justifyContent: 'space-between' }}
                              {...props}
                              onClick={() => handleTagSelection(option)}
                            >
                              {option}
                              {tagsList.includes(option) && (
                                <CheckIcon sx={{ color: yellow }} />
                              )}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              placeholder='Enter tag'
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  if (
                                    e.target.value.trim() !== '' &&
                                    !tagsList.includes(e.target.value.trim())
                                  ) {
                                    setTagsList([
                                      ...tagsList,
                                      e.target.value.trim(),
                                    ]);
                                  }
                                }
                              }}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  e.target.value = '';
                                }
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '8px',
                                  boxShadow: 'none',
                                  '&:hover': {
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                  },
                                  '&.Mui-focused': {
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm'>Featured Image</p>
                    {newPostMedia.featureImage !== '' ? (
                      <Box component='div'>
                        <img
                          src={
                            newPostMedia?.featureImage !== '' &&
                            (newPostMedia.featureFrom === 'link'
                              ? newPostMedia?.featureImage?.url
                              : URL.createObjectURL(newPostMedia?.featureImage))
                          }
                          alt={'Feature Image'}
                          className='rounded-lg h-[145px] w-[100%] mt-[15px] mb-[15px]'
                          style={{ objectFit: 'cover' }}
                        />
                        {newPostMediaError.type === 'featureImage' ? (
                          <p
                            style={{ color: red }}
                            className='text-[12px] w-full mb-[15px]'
                          >
                            {newPostMediaError.message}
                          </p>
                        ) : null}
                        <div className='flex justify-between flex-row-reverse items-center'>
                          <p
                            style={{ color: red }}
                            className={`text-sm font-medium cursor-pointer p-2 hover:bg-red-600/5 rounded-[8px]  duration-200`}
                            onClick={() => {
                              setDeletedIds((prev) => [
                                ...prev,
                                newPostMedia?.featureImage,
                              ]);
                              setNewPostMedia((pre) => ({
                                ...pre,
                                featureImage: '',
                              }));
                              setNewPostMediaError({ type: '', message: '' });
                            }}
                          >
                            <DeleteOutlineOutlinedIcon
                              size='small'
                              sx={{ color: red }}
                            />
                            Remove
                          </p>
                          <label
                            htmlFor='file-input'
                            className={`border border-[${yellow}] text-[${yellow}] m-aut self-start cursor-pointer text-sm font-medium p-2 rounded-[8px]  duration-200`}
                          >
                            Replace Image
                            <input
                              accept='image/*'
                              id='file-input'
                              type='file'
                              className='hidden'
                              onChange={(e) => {
                                handleOnChangeFeatureImage(e?.target?.files);
                              }}
                              onClick={() => {
                                setNewPostMediaError({ type: '', message: '' });
                              }}
                            />
                          </label>
                        </div>
                      </Box>
                    ) : (
                      <DragDropFile
                        accept='image/*'
                        onDrop={handleDropFeatureImage}
                        removeImage={handleRemoveImage}
                        selectedFiles={[]}
                        onChange={handleOnChangeFeatureImage}
                        onClick={onInputClick}
                        error={
                          newPostMediaError.type === 'featureImage' &&
                          newPostMediaError.message
                        }
                        showStaticFiles={true}
                        hideErrorFn={hideErrorMessages}
                      />
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* TypoGraphy */}
                <Box display={isShowBlock.isTypoOptions ? 'block' : 'none'}>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Paragraph</p>
                    <span style={{ color: gray }} className='text-[13px]'>
                      Start with the basic building block of all narrative.
                    </span>
                  </div>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Typography</p>

                    <div className='mt-[20px]'>
                      <p className='text-[11px] pb-[8px]'>Letter Case</p>
                      <div className='flex gap-2'>
                        <div
                          className={`w-[40px] text-[13px] h-[40px] cursor-pointer rounded-[8px]  text-center flex items-center justify-center`}
                          style={{
                            backgroundColor: Boolean(letterCases.underscore)
                              ? primaryColor
                              : 'unset',
                          }}
                          onClick={() => handleTypoOptions('underline')}
                        >
                          −
                        </div>
                        <div
                          className='w-[40px] h-[40px] text-[13px] cursor-pointer rounded-[8px]  text-center flex items-center justify-center'
                          style={{
                            backgroundColor: Boolean(letterCases.capitilize)
                              ? primaryColor
                              : 'unset',
                          }}
                          onClick={() => handleTypoOptions('capitalize')}
                        >
                          Aa
                        </div>
                        <div
                          className='w-[40px] h-[40px] text-[13px] cursor-pointer rounded-[8px]  text-center flex items-center justify-center'
                          style={{
                            backgroundColor: Boolean(letterCases.upperCase)
                              ? primaryColor
                              : 'unset',
                          }}
                          onClick={() => handleTypoOptions('uppercase')}
                        >
                          AA
                        </div>
                        <div
                          className='w-[40px] h-[40px] text-[13px] cursor-pointer rounded-[8px] text-center flex items-center justify-center'
                          style={{
                            backgroundColor: Boolean(letterCases.lowerCase)
                              ? primaryColor
                              : 'unset',
                          }}
                          onClick={() => handleTypoOptions('lowercase')}
                        >
                          aa
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* ImageGraphy */}
                <Box display={isShowBlock.isImageOptions ? 'block' : 'none'}>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Image</p>
                    <span style={{ color: gray }} className='text-[13px]'>
                      Insert an image to make a visual statement.
                    </span>
                  </div>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Settings</p>
                    <div className='mt-[20px]'>
                      <p className='text-[11px] pb-[8px]'>Image Size</p>
                      <SelectDropdown
                        className={'mt-0 '}
                        width={'100%'}
                        popoverWidth={'17vw'}
                        bgColor={primaryColor}
                        list={imageSizeList}
                        value={imageSize === '' ? imageSizeList[0] : imageSize}
                        placeholder='Select'
                        onClick={(value) => {
                          setImageSize(value);
                        }}
                      />
                    </div>
                    {Boolean(imageSize?.value === 'custom') && (
                      <div className='flex gap-5 mt-[20px]'>
                        <div>
                          <p className='text-[11px] pb-[8px]'>Width</p>
                          <input
                            type='text'
                            placeholder='Size'
                            className={`p-3 text-[13px] w-full !border-[${yellow}] border-[1px] rounded-[8px] font-["Inter"]`}
                            style={{
                              backgroundColor: primaryColor,
                            }}
                            value={imageWidth}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numericVal = value.replace(/[^0-9.]/, '');
                              setImageWidth(numericVal);
                            }}
                          />
                        </div>
                        <div>
                          <p className='text-[11px] pb-[8px]'>Height</p>
                          <input
                            type='text'
                            placeholder='Size'
                            className={`p-3 text-[13px] w-full !border-[${yellow}] border-[1px] rounded-[8px] font-["Inter"]`}
                            style={{
                              backgroundColor: primaryColor,
                            }}
                            value={imageHeight}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numericVal = value.replace(/[^0-9.]/, '');
                              setImageHeight(numericVal);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Box>
                {/* Video Graphy */}
                <Box display={isShowBlock.isVideoOptions ? 'block' : 'none'}>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Video</p>
                    <span style={{ color: gray }} className='text-[13px]'>
                      Embed a video from your media library or insert a new one
                      from URL.
                    </span>
                  </div>
                  <div
                    className={`py-5 px-4 border-b border-[${primaryColor}]`}
                  >
                    <p className='text-sm mb-[10px]'>Settings</p>

                    <div>
                      <MuiSwtich
                        label={'Autoplay'}
                        checked={Boolean(newPostVideoSettings?.autoplay)}
                        onChange={handleOnChangeVideoSetting}
                        name='autoplay'
                      />
                      <MuiSwtich
                        label={'Loop'}
                        checked={Boolean(newPostVideoSettings?.loop)}
                        onChange={handleOnChangeVideoSetting}
                        name='loop'
                      />
                      <MuiSwtich
                        label={'Muted'}
                        checked={Boolean(newPostVideoSettings?.muted)}
                        onChange={handleOnChangeVideoSetting}
                        name='muted'
                      />
                      <MuiSwtich
                        label={'Playback controls'}
                        checked={Boolean(
                          newPostVideoSettings?.playbackControls
                        )}
                        onChange={handleOnChangeVideoSetting}
                        name='playbackControls'
                      />
                      <MuiSwtich
                        label={'Play inline'}
                        checked={Boolean(newPostVideoSettings?.playInline)}
                        onChange={handleOnChangeVideoSetting}
                        name='playInline'
                      />
                    </div>
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className='flex flex-col items-start justify-start px-4 h-[calc(100vh-131px)] overflow-auto'>
                  <div className='w-full'>
                    <TextField
                      label={'Blog Meta Title'}
                      type='text'
                      name='blogTitle'
                      style={{
                        borderWidth: '1px',
                        borderColor: yellow,
                      }}
                      value={newPost?.blogTitle}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>

                  <div className='w-full'>
                    <TextField
                      label={'Blog Meta Keywords'}
                      type='text'
                      name='keywords'
                      style={{
                        borderWidth: '1px',
                        borderColor: yellow,
                      }}
                      value={newPost?.keywords}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>

                  <div className='w-full'>
                    <TextField
                      label={'Blog Meta Description'}
                      type='text'
                      name='blogMetaDescription'
                      style={{
                        borderWidth: '1px',
                        borderColor: yellow,
                      }}
                      value={newPost?.blogMetaDescription}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>

                  {/* PAGE LINKING */}
                  <div className='w-full pt-4'>
                    <PageLinking
                      links={links}
                      setLinks={setLinks}
                      editorHTML={editorHTML}
                    />
                  </div>
                </div>

              </TabPanel>
            </div>
          </section>
        )}
      </div>

      {/* Visibiblity PopOver */}
      <Popover
        id={visibilityId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            color: white,
          },
        }}
      >
        <Box bgcolor={secondaryColor} width={280} padding='15px' pb='40px'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography color={white} fontSize={17} fontFamily={'Inter-Medium'}>
              Visibility
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon sx={{ color: white }} fontSize='medium' />
            </IconButton>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              name='visibility'
              value='public'
              onChange={handleVisibilityChange}
              checked={Boolean(newPost?.visibility === 'public')}
            />
            <div className='flex  flex-col '>
              <span className='text-[13px] mt-[15px]'>Public</span>
              <p className={`text-[11px] text-[${gray}]`}>
                Visible to everyone.
              </p>
            </div>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              name='visibility'
              value='password_protected'
              onChange={handleVisibilityChange}
              checked={Boolean(newPost?.visibility === 'password_protected')}
            />
            <div className='flex  flex-col '>
              <span className='text-[13px] mt-[15px]'>Password protected</span>
              <p className={`text-[11px] text-[${gray}]`}>
                Only those the password can view this page.
              </p>
            </div>
          </Box>
          {newPost?.visibility === 'password_protected' &&
            newPost?.visibility !== '' && (
              <Box mt='10px'>
                <input
                  name='password'
                  placeholder='Enter Password'
                  className={`bg-transparent p-3 text-[12px] w-full !border-[${yellow}] border-[1px] rounded-[8px] `}
                  value={newPost?.password}
                  onChange={(e) =>
                    setNewPost((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                />
              </Box>
            )}
        </Box>
      </Popover>

      <CustomModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        title={'Delete Post Content'}
        width={500}>
        <div className="mt-[10px] pb-1 pl-1 pr-2">
          <p className='text-sm text-white'>Are you sure you want to delete previous blog content and generate new blog post?</p>
        </div>

        {/* Buttons */}
        <div className="flex items-center justify-end gap-5 mt-5">
          <div onClick={() => setModalOpen(false)} className="text-white text-sm cursor-pointer">
            <p style={Styles.cancelBtn}>Cancel</p>
          </div>
          <Button
            className={`!px-5 text-sm !normal-case`}
            style={Styles.activeBtn}
            onClick={() => {
              setModalOpen(false);
              setDeleteBlogContent(true);
              setEditorHTML('');
              setNewPost((prev) => ({
                ...prev,
                customURI: 'enter-uri',
                title: '',
              }));
              generateBlog();
            }}
          >
            Generate New Blog
          </Button>
        </div>
      </CustomModal>

      {/* Delete Blog Page Modal */}
      <DeleteBlogPage
        open={isOpenDeleteModal}
        data={deleteBlogData}
        close={closeDeleteModal}
        onDelete={handleOnDelete}
        loading={deleteLoading}
        error={deleteError}
      />
    </React.Fragment>
  );
};

export default AddNewBlogPage;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ fontFamily: 'Inter' }}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}