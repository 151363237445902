// Library Imports
import React, { useState, useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useSearchParams } from 'react-router-dom';
// Local Imports
import { gray } from '../../utils/style/GlobalVariables';
import TableMainBody from './TableBody';
import TableHeader from './TableHeader';
import { debounce } from '../../utils/utils';
const CustomTable = ({
  rows,
  columns,
  tableColumnExtensions,
  dataProviders,
  onRowClick,
  isUnits,
  open,
  isSelectedCampus,
  metaData,
}) => {
  // REFS
  const tableRef = useRef(null);
  // STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIds, setSelectedIds] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page') - 1) : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(searchParams.get('pageSize')) || 20
  );
  const handleChangePage = (event, newPage) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('page', newPage + 1);
      return params;
    });
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    const pageOfTotalRecords = Math.ceil(
      metaData?.total_record / event.target.value
    );
    const currentPage = parseInt(searchParams.get('page')) || 1;
    if (currentPage <= pageOfTotalRecords) {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set('pageSize', event.target.value);
        return params;
      });
      setRowsPerPage(event.target.value);
    }
  };
  const rowsToShow =
    rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [];
  // ONSCROLL
  const debouncedSetScrollPosition = debounce((scrollTop) => {
    setScrollPosition(scrollTop);
  }, 200);
  // ONSCROLL
  const onScroll = (event) => {
    debouncedSetScrollPosition(event.target.scrollTop);
  };
  useEffect(() => {
    const storedScrollPosition = JSON.parse(
      localStorage.getItem('scrollPosition')
    );
    if (storedScrollPosition !== null && !open) {
      setScrollPosition(storedScrollPosition);
      if (tableRef.current) {
        tableRef.current.scrollTop = storedScrollPosition;
      }
    }
  }, [open]);
  // Store scroll position in local storage
  useEffect(() => {
    localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition));
  }, [scrollPosition]);
  return (
    <React.Fragment>
      <div
        className={`flex flex-col  text-[${gray}] ${
          isSelectedCampus ? 'h-[calc(100vh-240px)]' : 'h-[calc(100vh-195px)] '
        } overflow-auto px-6`}
      >
        <TableContainer
          component={Paper}
          sx={{ height: '74vh' }}
          ref={tableRef}
          onScroll={(event) => onScroll(event)}
        >
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHeader
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
            {/* TABLE MAIN BODY ROWS */}
            <TableMainBody
              rows={metaData !== '' ? rows : rowsToShow}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              dataProviders={dataProviders}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              onRowClick={onRowClick}
              isUnits={isUnits}
            />
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          sx={{
            color: 'white',
            overflow: 'hidden',
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular': {
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 0,
              padding: 0,
              paddingLeft: '13px',
              borderTop: '0px !important',
            },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:first-of-type':
              {
                display: 'none',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:nth-child(3)':
              {
                fontSize: '15px',
                marginLeft: '15px',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > p:nth-child(2)':
              {
                fontSize: '12px',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > p:nth-child(4)':
              {
                marginLeft: 'auto',
                fontSize: '12px',
              },
            '& .MuiTablePagination-actions button > svg': {
              fontSize: '20px',
            },
            '& .MuiSvgIcon-root': {
              //   backgroundColor: gray,
              fill: gray,
            },
          }}
          count={metaData?.total_record || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </div>
    </React.Fragment>
  );
};
export default CustomTable;
