import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from './Typography';
import { gray } from '../utils/style/GlobalVariables';
import { Checkbox as MuiCheckbox, FormLabel, Box } from '@mui/material';

import Amenities from '../navigation/screens/Dashboard/Campus/Campuses/AddCampus/AddCampusComponents/Amenitites';
import UnCheckedCheckboxIcon from '../assets/icons/UnCheckedBox';
import CheckedCheckboxIcon from '../assets/icons/CheckedCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

const checkbox = ({
  label,
  size,
  color,
  checked,
  fontWeight,
  onChange,
  amenities,
}) => (
  <FormControlLabel
    control={
      <Checkbox
        size={'small'}
        sx={{
          '& .MuiSvgIcon-root': {
            ...(!checked
              ? {
                  width: 16,
                  height: 16,
                  borderStyle: 'solid',
                  borderRadius: 1,
                  borderWidth: 1,
                  borderColor: gray,
                }
              : {
                  width: 18,
                  height: 18,
                  marginLeft: -0.25,
                  marginTop: -0.25,
                }),
          },
        }}
        onChange={onChange}
        checked={checked}
        color={'success'}
      />
    }
    label={
      <>
        {amenities ? (
          <div className='flex flex-row'>
            <Typography
              variant='interRegular_13'
              className='overflow-clip text-clip max-h-5 w-[14.2rem]'
              color={color}
              fontWeight={fontWeight}
            >
              {label}
            </Typography>
            {label?.length > 34 ? (
              <Typography
                variant='interRegular_13'
                className='overflow-clip text-clip max-h-5 w-3'
                color={color}
                fontWeight={fontWeight}
              >
                ...
              </Typography>
            ) : null}
          </div>
        ) : (
          <Typography
            variant='interRegular_13'
            color={color}
            fontWeight={fontWeight}
          >
            {label}
          </Typography>
        )}
      </>
    }
    size={size}
    color={color}
  />
);

//Library Imports

export const CustomCheckBox = ({ label, checked, value, onChange, name }) => {
  return (
    <React.Fragment>
      <Box component={'div'} display='flex' alignItems='center' gap='8px'>
        <MuiCheckbox
          icon={<UnCheckedCheckboxIcon />}
          checkedIcon={<CheckedCheckboxIcon />}
          onChange={onChange}
          checked={checked}
          value={value}
          name={name}
          sx={{ padding: '0px' }}
          placeholder={label}
          className='checkbox'
        />
        <FormLabel sx={{ fontSize: '13px', color: 'text.primary' }}>
          {label}
        </FormLabel>
      </Box>
    </React.Fragment>
  );
};
export const CustomRemoveBox = ({ label, checked, value, onChange, name }) => {
  return (
    <React.Fragment>
      <Box component={'div'} display='flex' alignItems='center' gap='8px'>
        <MuiCheckbox
          icon={<UnCheckedCheckboxIcon />}
          checkedIcon={<IndeterminateCheckBoxOutlinedIcon />}
          onChange={onChange}
          checked={checked}
          value={value}
          name={name}
          sx={{
            padding: '0px',
            '.MuiSvgIcon-root': {
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '8px',
            },
          }}
          placeholder={label}
          className='checkbox'
        />
        <FormLabel sx={{ fontSize: '13px' }}>{label}</FormLabel>
      </Box>
    </React.Fragment>
  );
};

export default checkbox;
