import React, { useRef } from 'react';
import { mapApiKey } from '../../utils/network/constants';
import {
  LoadScript,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import TextField from '../inputField';

const libraries = ['places'];
const AutoCompleteGooglePlace = ({ setnewCampus, newCampus }) => {
  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: mapApiKey,
    libraries: libraries,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setnewCampus((prev) => ({
        ...prev,
        street: place.formatted_address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
      }));
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };
  const handleStreetChange = (event) => {
    const streetValue = event.target.value;
    setnewCampus((prev) => ({
      ...prev,
      street: streetValue,
    }));
  };

  return (
    <div id='autocomplete-google-map'>
      <LoadScript googleMapsApiKey={mapApiKey} libraries={libraries}>
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <TextField
              type='text'
              label={'Street'}
              value={newCampus?.street}
              placeholder=''
              onChange={handleStreetChange}
            />
          </StandaloneSearchBox>
        ) : null}
      </LoadScript>
    </div>
  );
};

export default AutoCompleteGooglePlace;
