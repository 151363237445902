import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import {
  gray,
  primaryColor,
  secondaryColor,
  white,
} from '../../utils/style/GlobalVariables';
import { CustomCheckBox } from '../checkBox';

const Cell = styled(TableCell)(({ theme }) => ({
  color: gray,
  backgroundColor: 'transparent',
  fontFamily: 'Inter-Regular',
  fontSize: '12px',
  padding: '20px 10px',
  borderBottom: `1px solid ${gray}`,
  '&:last-child': {
    borderBottom: 'none !important',
  },
}));

const SelectionCell = styled(TableCell)(({ theme }) => ({
  color: gray,
  backgroundColor: 'transparent',
  fontFamily: 'Inter-Regular',
  fontSize: '12px',
  borderBottom: 'none',
  padding: '18px 0px',
  '&:last-child': {
    // borderBottom: `1px solid ${gray}`,
  },
}));

const Row = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  borderBottom: `1px solid ${gray}`,
}));

const TableHeader = ({
  rows,
  columns,
  selectedIds,
  setSelectedIds,
  tableColumnExtensions,
}) => {
  return (
    <React.Fragment>
      <TableHead
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: primaryColor,
          zIndex: 1,
        }}
      >
        <Row>
          {selectedIds?.length > 0 ? (
            <SelectionCell width={100}>
              <div
                style={{ backgroundColor: secondaryColor }}
                className='flex flex-row  justify-content-between p-1 align-items-center rounded'
              >
                <div
                  style={{ backgroundColor: primaryColor }}
                  className='flex flex-row justify-content-between align-items-center border-end rounded p-1 px-2'
                >
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedIds([]);
                    }}
                    className='cursor-pointer'
                    style={{
                      width: 16,
                      height: 16,
                      borderStyle: 'solid',
                      borderRadius: 4,
                      borderWidth: 1,
                      borderColor: 'white',
                      textAlign: 'center',
                      backgroundColor: primaryColor,
                    }}
                  >
                    <div
                      style={{ position: 'relative', top: -5 }}
                      className='text-white'
                    >
                      -
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 13,
                      marginLeft: 10,
                      color: white,
                      backgroundColor: secondaryColor,
                    }}
                    className='w-max absolute left-[35px] top-[18px] p-1 rounded pr-2'
                  >
                    {selectedIds?.length} Selected
                  </div>
                </div>
              </div>
            </SelectionCell>
          ) : (
            <Cell width={100}>
              <CustomCheckBox
                checked={Boolean(selectedIds?.length > 0 ? true : false)}
                onChange={() => {
                  if (selectedIds?.length === 0) {
                    setSelectedIds(() => rows?.map((row) => row?.id));
                  } else {
                    setSelectedIds([]);
                  }
                }}
              />
            </Cell>
          )}

          {columns?.map((col, colIndex) => {
            // GET COLUMN EXTENSION
            const columnExtension = tableColumnExtensions?.find(
              (item) => item?.columnName === col?.name
            );
            const width = columnExtension?.width || 100;
            return (
              <Cell
                key={colIndex}
                color={gray}
                width={width || 100}
                style={{ minWidth: width }}
              >
                {selectedIds?.length === 0 ? col?.title : ''}
              </Cell>
            );
          })}
        </Row>
      </TableHead>
    </React.Fragment>
  );
};

export default TableHeader;
