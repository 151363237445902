// Library Imports
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

// Local Imports
import { secondaryColor } from '../../../../../../utils/style/GlobalVariables';
import { SearchBar } from '../../../../../../components/SearchBar/SearchBar';
import UnitsTable from '../../../../../../components/Tables/Table';
import { InsideSpinner } from '../../../../../../components/Spinner/Spinner';
import ServerError from '../../../../../../components/serverError';
import { MyPopper } from '../../../../../../components/Filter/Filters';
import { Dropdown } from '../../../../../../components/Dropdown';
import { Styles } from '../../../../../../utils/style/GlobalStyles';
import CustomTable from '../../../../../../components/CustomTable';

const UnitsUI = ({
  selectionIds,
  setSelectionIds,
  unitColumnData,
  unitColumnExtensionsData,
  unitRowData,
  dataProviders,
  onRowSelect,
  onChangeSearch,
  onUnitsSearch,
  onClear,
  searchText,
  loading,
  networkError,
  reCallListing,
  onScroll,
  anchorEl,
  id,
  open,
  listOpen,
  handleClick,
  handleClose,
  selectedCampusString,
  clickAwayHandler,
  handleClickForParent,
  filters,
  onChangeFilter,
  onClearFilter,
  clearPriceRange,
  listingCallWithPriceRange,

  handlePriceChanges,
  buyValue,
  leaseValue,
  lengthFilter,
  widthFilter,
  isSelectedCampus,
  setIsSelectedCampus,
  openModal,
  metaData,
}) => {
  // Show Selected Campuses

  const [selectedCampus, setSelectedCampus] = useState([]);

  useEffect(() => {
    checkCampusFilter();
    // eslint-disable-next-line
  }, [filters?.Campus]);

  const checkCampusFilter = () => {
    let selected = [];

    // eslint-disable-next-line
    filters?.Campus?.map((item) => {
      if (item.value) {
        selected.length > 0 ? selected.push(item) : (selected[0] = item);
      } else {
        setIsSelectedCampus(false);
      }
    });

    if (selected.length > 0) {
      setSelectedCampus(selected);
      setIsSelectedCampus(true);
    } else {
      setIsSelectedCampus(false);
    }
  };

  return (
    <div className='main-container'>
      {/* Search Bar Row */}
      <div className='flex flex-row justify-between align-items-center pb-3 pt-6 px-6 gap-5'>
        <div
          style={{
            background: secondaryColor,
            height: 38,
            width: '100px',
            cursor: !searchText ? 'pointer' : 'not-allowed',
          }}
          onClick={!searchText ? handleClick : null}
          className='flex justify-center text-sm flex-row align-items-center rounded-lg pointer p-2'
        >
          <FilterListIcon className='mr-2' fontSize='small' color='secondary' />
          <p style={Styles.disableBtnText}>Filter</p>
        </div>
        <SearchBar
          disabled={selectionIds.length > 0 ? true : false}
          onSearch={() => onUnitsSearch()}
          onClear={() => onClear()}
          onChange={onChangeSearch}
          value={searchText}
        />
      </div>

      {/* To Show Selected Campus */}
      {isSelectedCampus && (
        <div className='flex items-center gap-2 bg-[#CDA950] py-2 pl-2 pr-1 mt-1 rounded-lg mx-6 w-fit'>
          <span className='text-sm'>
            Campus:
          </span>

          <div className='flex items-center text-xs'>
            {selectedCampus.length > 0 &&
              selectedCampus.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                     `${item.title}`
                    ) : (
                      `, ${item.title}`
                    )}
                  </React.Fragment>
                );
              })}
          </div>

          <span
            className='cursor-pointer'
            onClick={() => {
              setIsSelectedCampus(false);
              onClearFilter();
            }}
          >
            <CloseIcon
              className='ml-2 mb-[4px]'
              fontSize='inherit'
              color='primary'
            />
          </span>
        </div>
      )}

      {/* Unit Table */}
      {loading ? (
        <InsideSpinner />
      ) : networkError ? (
        <ServerError reCallListing={reCallListing} />
      ) : (
        // <UnitsTable
        //   rows={unitRowData}
        //   columns={unitColumnData}
        //   tableColumnExtensions={unitColumnExtensionsData}
        //   dataProviders={dataProviders}
        //   selectionIds={selectionIds}
        //   setSelectionIds={setSelectionIds}
        //   onRowSelect={onRowSelect}
        //   unitsSelectedCampus={isSelectedCampus}
        // />
        <CustomTable
          rows={unitRowData}
          columns={unitColumnData}
          tableColumnExtensions={unitColumnExtensionsData}
          dataProviders={dataProviders}
          onRowClick={onRowSelect}
          isUnits={true}
          open={openModal}
          isSelectedCampus={isSelectedCampus}
          metaData={Object.keys(metaData).length > 0 ? metaData : ''}
        />
      )}

      {/* Filter Popover */}
      {anchorEl && (
        <MyPopper
          clickAwayHandler={clickAwayHandler}
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onClear={onClearFilter}
        >
          <div className='max-h-[400px] overflow-auto'>
            <Dropdown
              onChangeFilter={onChangeFilter}
              options={filters?.Campus}
              title='Campus'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='checkbox'
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              options={['Price Range (For Sale)']}
              title='Price Range (For Sale)'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='range'
              symbol={'$'}
              name={'buyPrice'}
              minValue={10000}
              maxValue={150000}
              handleChanges={handlePriceChanges}
              rangeValue={buyValue}
              clearPriceRange={clearPriceRange}
              listingCallWithPriceRange={listingCallWithPriceRange}
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              options={['Price Range (For Lease)']}
              title='Price Range (For Lease)'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='range'
              symbol={'$'}
              name={'leasePrice'}
              minValue={50}
              maxValue={1500}
              handleChanges={handlePriceChanges}
              rangeValue={leaseValue}
              clearPriceRange={clearPriceRange}
              listingCallWithPriceRange={listingCallWithPriceRange}
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              onChangeFilter={onChangeFilter}
              options={filters?.UnitTypes}
              title='Unit Type'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              name={'unittypes'}
              type='checkbox'
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              onChangeFilter={onChangeFilter}
              options={filters?.Availability}
              title='Availability'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              name={'availability'}
              type='checkbox'
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              options={['Width']}
              title='Width'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='range'
              symbol={'ft'}
              name={'width'}
              minValue={0}
              maxValue={99}
              handleChanges={handlePriceChanges}
              rangeValue={widthFilter}
              clearPriceRange={clearPriceRange}
              listingCallWithPriceRange={listingCallWithPriceRange}
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <Dropdown
              options={['Length']}
              title='Length'
              handleClickForParent={handleClickForParent}
              listOpen={listOpen}
              type='range'
              symbol={'ft'}
              name={'length'}
              minValue={0}
              maxValue={99}
              handleChanges={handlePriceChanges}
              rangeValue={lengthFilter}
              clearPriceRange={clearPriceRange}
              listingCallWithPriceRange={listingCallWithPriceRange}
            />

            <div className='mt-3 border-t border-[#0F0F14]'></div>

            <div className='mb-3'>
              <Dropdown
                onChangeFilter={onChangeFilter}
                options={filters?.Status}
                title='Status'
                handleClickForParent={handleClickForParent}
                listOpen={listOpen}
                name={'status'}
                type='checkbox'
              />
            </div>
          </div>
        </MyPopper>
      )}
    </div>
  );
};

export default UnitsUI;
