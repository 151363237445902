import ImageGalleryNew from '../../Gallery';
import { gray, yellow } from '../../../utils/style/GlobalVariables';
import AssetsImages from '../../../assets';
import { Typography } from '@mui/material';

const UnitPreviewPage = ({ data }) => {
  const images = [AssetsImages.loginBG];
  return (
    <section>
      <div className='flex items-center justify-center mt-10'>
        <p className='font-semibold text-white text-3xl lg:w-96 text-center'>
          Unit Preview Page
        </p>
      </div>
      <div className='container lg:pl-8 my-10 mx-auto'>
        <div className='lg:-mx-6 lg:flex lg:items-center gap-4'>
          {data?.unitImages?.length > 0 ? (
            <ImageGalleryNew images={data?.unitImages} />
          ) : (
            <ImageGalleryNew images={images} />
          )}
          <div className='lg:w-1/2 lg:px-6 flex flex-col justify-center'>
            <h1 className='font-semibold text-white text-2xl lg:w-96 mb-4'>
              {data?.title}
            </h1>
            <Typography
              style={{ color: gray }}
              component={"div"}
              sx={{ 
                a: {
                  color: yellow
                },
                ul: {
                  listStyleType: 'disc',
                  paddingLeft: 2,
                }
               }}
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnitPreviewPage;